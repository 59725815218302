<template>
  <div class="navigtor">
    <div class="navigtorBanner">
      <router-link to="home">
        <div class="image">
          <img class="logo" src="@/assets/PC/logo.png" alt="" />
          <span>Cloudun AI</span>
        </div>
      </router-link>
      <!-- 产品矩阵，最佳实践，关于我们 -->
      <el-tabs v-model="homeName" @tab-click="handleClick">
        <el-tab-pane name="home">
          <span slot="label">
            {{ $t("route.home") }}
          </span>
        </el-tab-pane>
        <el-tab-pane :name="page" ref="product">
          <span slot="label">
            <el-dropdown
              @command="commandDropdown"
              :class="{ isPr: this.homeName === 'product' }"
            >
              <span class="el-dropdown-link">
                {{ $t("route.productMatrix")
                }}<i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <router-link v-if="$i18n.locale !== 'en' && $i18n.locale !== 'id'" to="ads"
                  ><el-dropdown-item :command="$t('route.productAITitle1')">{{
                    $t("route.aiTab")
                  }}</el-dropdown-item></router-link
                >
                <router-link v-if="$i18n.locale !== 'en' && $i18n.locale !== 'id'" to="ekyc"
                  ><el-dropdown-item command="EKYC">{{
                    $t("route.numberTab")
                  }}</el-dropdown-item></router-link
                >
                <router-link v-if="$i18n.locale === 'en' || $i18n.locale === 'id'" to="credit"
                  ><el-dropdown-item
                    :command="$t('credit.productCreditTitle1')"
                    >{{ $t("route.creditTab") }}</el-dropdown-item
                  ></router-link
                >
                <router-link v-else to="zhCredit"
                  ><el-dropdown-item
                    :command="$t('credit.productCreditTitle1')"
                    >{{ $t("route.creditTab") }}</el-dropdown-item
                  ></router-link
                >
                <router-link v-if="$i18n.locale !== 'en' && $i18n.locale !== 'id'" to="consult"
                  ><el-dropdown-item :command="$t('route.consultTab')">{{
                    $t("route.consultTab")
                  }}</el-dropdown-item></router-link
                >
              </el-dropdown-menu>
            </el-dropdown>
          </span>
        </el-tab-pane>
        <el-tab-pane name="practice">
          <span slot="label">
            {{ $t("route.BestPractices") }}
          </span>
        </el-tab-pane>
        <el-tab-pane :label="$t('route.Aboutus')" name="aboutUs">
          <span slot="label">
            {{ $t("route.Aboutus") }}
          </span>
        </el-tab-pane>
        <el-tab-pane :label="$t('route.News')" name="news" v-if="$i18n.locale === 'en' || $i18n.locale === 'id'">
          <span slot="label">
            {{ $t("route.News") }}
          </span>
        </el-tab-pane>
      </el-tabs>
      <!-- 中文，免费试用 -->
      <el-menu class="el-menu-demo" mode="horizontal" @select="changeLanguage">
        <el-submenu index="1">
          <template slot="title">{{ language }}</template>
          <el-menu-item index="zh">中文</el-menu-item>
          <el-menu-item index="en">English</el-menu-item>
          <el-menu-item index="es">Español</el-menu-item>
          <el-menu-item index="id">Bahasa</el-menu-item>
        </el-submenu>
        <!-- 免费试用 -->
        <!-- <el-submenu index="2">
              <template slot="title">{{$t('route.freeTrial')}}</template>
              <el-menu-item index="2-1">
                <el-popover
                  placement="top-start"
                  width="200"
                  :transition="''"
                  trigger="hover">
                  <el-button slot="reference">{{$t('route.download')}}</el-button>
                  <template>
                    <span>
                      <p>{{$t('route.download')}}</p>
                      <div class="qrCode"></div>
                    </span>
                  </template>
                </el-popover>
              </el-menu-item>
              </el-submenu> -->
      </el-menu>
    </div>
  </div>
</template>

<script>
export default {
  name: 'navigtorHeader',
  data () {
    return {
      language: '中文',
      languagea: localStorage.getItem('language') || '',
      homeName: JSON.parse(localStorage.getItem('pageShow'))
        ? JSON.parse(localStorage.getItem('pageShow'))
        : '',
      page: 'page',
      routelist: [
        {
          index: 0,
          path: '/ads'
        },
        {
          index: 1,
          path: '/ekyc'
        },
        {
          index: 2,
          path: '/credit'
        },
        {
          index: 3,
          path: '/consult'
        }
      ]
    }
  },
  created () {
    this.changeLanguage()
    this.$nextTick(() => {
      this.homeName = JSON.parse(localStorage.getItem('pageShow'))
        ? JSON.parse(localStorage.getItem('pageShow'))
        : ''
    })
  },
  methods: {
    commandDropdown (e) {
      localStorage.setItem('pageShow', JSON.stringify('product'))
      this.homeName = 'product'
    },
    // 导航栏中间产品矩阵部分点击事件
    handleSelect (e) {
      switch (e) {
        case '2-1':
          this.page = 'subPage'
          this.name = 'ai'
          break
        case '2-2':
          this.page = 'subPage'
          this.name = 'number'
          break
        case '2-3':
          this.page = 'subPage'
          this.name = 'credit'
          break
        case '2-4':
          this.page = 'subPage'
          this.name = 'consult'
          break
      }
    },
    changeLanguage (e) {
      // 字体切换
      const donm = document.querySelector('#app')
      if (e) {
        if (e === 'zh') {
          donm.style.fontFamily = 'Microsoft YaHei'
        } else {
          donm.style.fontFamily = 'Inter'
        }
      } else {
        if (this.$i18n.locale === 'zh') {
          donm.style.fontFamily = 'Microsoft YaHei'
        } else {
          donm.style.fontFamily = 'Inter'
        }
      }

      if (this.languagea === '') {
        this.languagea = e
      }
      const languageb = e || this.languagea
      if (!['en', 'zh', 'id', 'es'].includes(languageb)) return

      this.$i18n.locale = languageb // 设置给本地的i18n插件
      localStorage.setItem('language', languageb)
      this.$nextTick(() => {
        if (languageb === 'en') {
          this.language = 'English'
          localStorage.setItem('languageText', 'English')
        } else if (languageb === 'zh') {
          this.language = '中文'
          localStorage.setItem('languageText', '中文')
        } else if (languageb === 'id') {
          this.language = 'Bahasa'
          localStorage.setItem('languageText', 'Bahasa')
        } else if (languageb === 'es') {
          this.language = 'Español'
          localStorage.setItem('languageText', 'Español')
        }
      })

      // 以后如果需要更新不同语言不同表现时可以使用
      // this.$nextTick(() => {
      //   if (languageb === 'en') {
      //     this.language = this.$t('route.English')
      //     localStorage.setItem('languageText', JSON.stringify(this.$t('route.English')))
      //     if (e) location.reload()
      //   } else if (languageb === 'zh') {
      //     this.language = this.$t('route.Chinese')
      //     localStorage.setItem('languageText', JSON.stringify(this.$t('route.Chinese')))
      //     if (e) location.reload()
      //   } else if (languageb === 'id') {
      //     this.language = this.$t('route.Indonesia')
      //     localStorage.setItem('languageText', JSON.stringify(this.$t('route.Indonesia')))
      //     if (e) location.reload()
      //   } else if (languageb === 'es') {
      //     this.language = this.$t('route.Mexico')
      //     localStorage.setItem('languageText', JSON.stringify(this.$t('route.Mexico')))
      //     if (e) location.reload()
      //   }
      // })
    },
    handleClick (e) {
      if (e.index === '1') return
      // if (e.index === '1') {
      //   const blue = this.routelist.some(item => item.path === this.$route.path)
      //   if (!blue) {
      //     this.$router.replace({ path: '/ads' })
      //     this.homeName = 'product'
      //     localStorage.setItem('pageShow', JSON.stringify('product'))
      //     window.scrollTo(0, 0)
      //   } else {
      //     let routeIndex = this.routelist.find(item => item.path === this.$route.path).index
      //     if (routeIndex === 3) {
      //       this.$router.replace({ path: '/ads' })
      //       this.homeName = 'product'
      //       localStorage.setItem('pageShow', JSON.stringify('product'))
      //       window.scrollTo(0, 0)
      //     } else {
      //       routeIndex++
      //       const path = this.routelist.find(item => item.index === routeIndex).path
      //       this.$router.replace({
      //         path
      //       })
      //       window.scrollTo(0, 0)
      //     }
      //   }
      // } else {
      this.homeName = e._props.name
      const path = e._props.name
      this.homeName = path
      localStorage.setItem('pageShow', JSON.stringify(path))
      if (`/${path}` !== this.$route.path) {
        this.$router.push(`/${path}`)
      }
      window.scrollTo(0, 0)
    }
    // }
  },
  watch: {
    '$i18n.locale': function (val) {
      const white = ['en', 'id']
      if (!white.includes(val)) {
        if (this.$route.path === '/news') {
          this.$router.replace('/')
        }
        if (this.$route.path === '/credit') {
          this.$router.replace('/zhCredit')
        }
      } else {
        if (this.$route.path === '/zhCredit') {
          this.$router.replace('/credit')
        }
      }
    }
  }
}
</script>

<style scoped lang="less">
div,
p,
h1,
h2,
h3,
h4,
h5,
h6,
span,
img {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
li {
  list-style: none;
}
ul {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
a {
  text-decoration: none;
  color: #696969;
}
.isPr {
  border-bottom: 2px solid #000;
}
.navigtor {
  width: 100%;
  background-color: #fff;
  position: fixed;
  top: 0;
  left: 0;
  box-shadow: 0px 0.02rem 0.06rem 0px rgba(218, 218, 218, 0.5);
  height: 1.5rem;
  z-index: 999;
}
.navigtorBanner {
  box-sizing: border-box;
  width: 1100px;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: auto !important;
  background-color: #fff;
  .image {
    display: flex;
    align-items: center;
    .logo {
      width: 0.62rem;
      height: 0.72rem;
    }
    span {
      width: 3rem;
      text-align: left;
      font-family: "Microsoft YaHei";
      margin-left: 5px;
      font-size: 0.32rem;
      color: #333333;
      font-weight: 600;
      &:hover {
        color: #80c3b9;
      }
    }
  }
}
.el-popover {
  box-sizing: border-box;
  width: 6rem !important;
  height: 3.2rem !important;
  span {
    padding-top: 0.2rem;
    display: flex !important;
    justify-content: space-between;
    align-items: center;
    p {
      width: 1.7rem;
      font-weight: 700;
    }
    .qrCode {
      width: 1.8rem;
      height: 1.8rem;
    }
  }
}
/deep/ .el-tabs__nav-wrap {
  margin-bottom: 0.5rem;
  &::after {
    background-color: #fff !important;
  }
}
/deep/ .el-tabs__active-bar {
  background-color: #333333 !important;
}
/deep/ .el-menu {
  /deep/ .el-submenu__title,
  .el-menu-item {
    font-size: 0.24rem !important;
    color: #333333;
  }
}
/deep/ .navigtorBanner {
  .el-dropdown {
    &:hover {
      color: #80c3b9;
    }
  }
  .el-tabs__item {
    &:hover {
      color: #80c3b9;
    }
    &.is-active {
      // color: #80C3B9;
      color: #333333;
    }
  }
  .el-menu--horizontal {
    border-bottom: none !important;
  }
  .el-menu--horizontal > .el-submenu .el-submenu__title {
    color: #303133;
    &:hover {
      color: #80c3b9;
    }
  }
  .el-tabs {
    margin-left: 20%;
  }
}
/deep/ .el-dropdown-menu__item:not(.is-disabled):hover {
  background-color: rgba(143, 193, 185, 0.3);
  color: #80c3b9;
}
/deep/ .el-submenu.is-active .el-submenu__title,
.el-menu--horizontal > .el-submenu.is-active .el-submenu__title {
  border-bottom: 1px solid #fff !important;
}
</style>
